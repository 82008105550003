export const getTrackingParamsString = async () => {
  const trackingParams: Record<string, string> = {}

  try {
    const partnerList = await $fetch<Record<string, string>>(
      'https://yield-op-idsync.live.streamtheworld.com/partnerIds',
      { credentials: 'include' }
    )
    Object.assign(trackingParams, partnerList)
  } catch {
    console.warn('Failed to fetch partner IDs')
  }

  if (window?.adtonosListenerId) {
    trackingParams.adtonosListenerId = window.adtonosListenerId
  }

  if (window?.com_adswizz_synchro_listenerid) {
    trackingParams.aw_0_req_lsid = window.com_adswizz_synchro_listenerid
  }

  return Object.keys(trackingParams).length ? new URLSearchParams(trackingParams).toString() : ''
}
