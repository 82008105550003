import type {
  CardAnnouncement,
  CardSocialMedia,
  CardSupportDonations,
  CardWebsite,
  RadioCardData,
} from '~/api/stationCards.api'

export const useStationCards = (radioId: MaybeRefOrGetter<string>) => {
  const nuxtApp = useNuxtApp()
  const {
    data: radioCardData,
    status,
    error,
  } = useAsyncData<RadioCardData[]>(
    `station-cards-${unref(radioId)}`,
    () => $fetch(`/api/stations/${unref(radioId)}/cards`),
    {
      getCachedData(key) {
        return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
      },
    }
  )

  const websiteCard = computed(() => {
    const card = radioCardData.value?.find((card) => card.type === 'CardWebsite') as CardWebsite

    if (card && card.enabled && card.url) {
      return {
        enabled: card.enabled,
        url: card.url,
        label: card.btnLabel,
        image: card.image,
      }
    }

    return null
  })

  const donationCard = computed(() => {
    const card = radioCardData.value?.find((card) => card.type === 'CardSupportDonations') as CardSupportDonations

    if (card && card.enabled && (card.paypal || card.cashapp)) {
      return {
        enabled: card.enabled,
        title: card.title,
        description: card.description,
        paypal: card.paypal,
        cashapp: card.cashapp,
      }
    }

    return null
  })

  const announcementCard = computed(() => {
    const card = radioCardData.value?.find((card) => card.type === 'CardAnnouncement') as CardAnnouncement

    if (card && card.announcements.length) {
      const announcement = card.announcements[0]

      const date = new Date(announcement.publishDate * 1000)
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }

      return {
        publishedAt: date.toLocaleDateString('en-US', options),
        title: announcement.title,
        description: announcement.content,
      }
    }

    return null
  })

  const socialLinks = computed(() => {
    const card = radioCardData.value?.find((card) => card.type === 'CardSocialMedia') as CardSocialMedia

    if (card && card.enabled) {
      const { facebook, twitter, instagram, snapchat, youtube, spotify, tiktok } = card

      return Object.fromEntries(
        Object.entries({
          facebook: facebook?.url,
          twitter: twitter?.url,
          instagram: instagram?.url,
          snapchat: snapchat?.url,
          youtube: youtube?.url,
          spotify: spotify?.url,
          tiktok: tiktok?.url,
        }).filter(([_, value]) => value !== undefined)
      )
    }

    return null
  })

  return {
    radioCards: radioCardData,
    websiteCard,
    donationCard,
    announcementCard,
    socialLinks,
    status,
    error,
  }
}
